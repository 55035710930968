const preset = {
    bg: {
        default: '',
        styles: {
            common: {
                'filled-primary': 'bg-clear',
                'filled-secondary': 'bg-clear',
                'filled-success': 'bg-clear',
                'filled-info': 'bg-clear',
                'filled-warning': 'bg-clear',
                'filled-danger': 'bg-clear',
                'filled-disabled': 'bg-clear',
                'filled-light': 'bg-clear',
                'filled-clear': 'bg-clear',
                'filled-dark': 'bg-clear',
                'filled-none': 'bg-clear',
                'outlined-primary': 'bg-transparent',
                'outlined-secondary': 'bg-transparent',
                'outlined-success': 'bg-transparent',
                'outlined-info': 'bg-transparent',
                'outlined-warning': 'bg-transparent',
                'outlined-danger': 'bg-transparent',
                'outlined-disabled': 'bg-transparent',
                'outlined-clear': 'bg-transparent',
                'outlined-light': 'bg-transparent',
                'outlined-dark': 'bg-transparent',
                'outlined-none': 'bg-transparent',
                'contained-primary': 'bg-primary',
                'contained-secondary': 'bg-secondary',
                'contained-success': 'bg-success',
                'contained-info': 'bg-info',
                'contained-warning': 'bg-warning',
                'contained-danger': 'bg-danger',
                'contained-disabled': 'bg-disabled',
                'contained-clear': 'bg-clear',
                'contained-light': 'bg-light',
                'contained-dark': 'bg-dark',
                'contained-none': 'bg-clear',
                'light-primary': 'bg-clear',
                'light-secondary': 'bg-clear',
                'light-success': 'bg-clear',
                'light-info': 'bg-clear',
                'light-warning': 'bg-clear',
                'light-danger': 'bg-clear',
                'light-disabled': 'bg-clear',
                'light-clear': 'bg-clear',
                'light-light': 'bg-clear',
                'light-dark': 'bg-clear',
                'light-none': 'bg-clear',
            },
            nonDisabledStyles: {
                'filled-primary': 'focus:outline-none',
                'filled-secondary': 'focus:outline-none',
                'filled-success': 'focus:outline-none',
                'filled-info': 'focus:outline-none',
                'filled-warning': 'focus:outline-none',
                'filled-danger': 'focus:outline-none',
                'filled-disabled': 'focus:outline-none',
                'filled-light': 'focus:outline-none',
                'filled-dark': 'focus:outline-none',
                'filled-none': 'bg-clear focus:outline-none',
                'outlined-primary': 'focus:outline-none',
                'outlined-secondary': 'focus:outline-none',
                'outlined-success': 'focus:outline-none',
                'outlined-info': 'focus:outline-none',
                'outlined-warning': 'focus:outline-none',
                'outlined-danger': 'focus:outline-none',
                'outlined-disabled': 'focus:outline-none',
                'outlined-light': 'focus:outline-none',
                'outlined-dark': 'focus:outline-none',
                'outlined-none': 'bg-clear focus:outline-none',
                'contained-primary': 'focus:outline-none',
                'contained-secondary': 'focus:outline-none',
                'contained-success': 'focus:outline-none',
                'contained-info': 'focus:outline-none',
                'contained-warning': 'focus:outline-none',
                'contained-danger': 'focus:outline-none',
                'contained-disabled': 'focus:outline-none',
                'contained-light': 'focus:outline-none',
                'contained-dark': 'focus:outline-none',
                'contained-none': 'bg-clear focus:outline-none',
                'light-primary': 'focus:outline-none',
                'light-secondary': 'focus:outline-none',
                'light-success': 'focus:outline-none',
                'light-info': 'focus:outline-none',
                'light-warning': 'focus:outline-none',
                'light-danger': 'focus:outline-none',
                'light-disabled': 'focus:outline-none',
                'light-light': 'focus:outline-none',
                'light-dark': 'focus:outline-none',
                'light-none': 'bg-clear focus:outline-none',
            },
            hoverable: {
                'filled-primary': 'hover:bg-primary hover:bg-opacity-20',
                'filled-secondary': 'hover:bg-secondary hover:bg-opacity-20',
                'filled-success': 'hover:bg-success hover:bg-opacity-20',
                'filled-info': 'hover:bg-info hover:bg-opacity-20',
                'filled-warning': 'hover:bg-warning hover:bg-opacity-20',
                'filled-danger': 'hover:bg-danger hover:bg-opacity-20',
                'filled-disabled': 'hover:bg-disabled',
                'filled-light': 'hover:bg-light',
                'filled-dark': 'hover:bg-dark hover:bg-opacity-20',
                'filled-none': '',
                'outlined-primary': 'hover:bg-primary',
                'outlined-secondary': 'hover:bg-secondary',
                'outlined-success': 'hover:bg-success',
                'outlined-info': 'hover:bg-info',
                'outlined-warning': 'hover:bg-warning',
                'outlined-danger': 'hover:bg-danger',
                'outlined-disabled': 'hover:bg-disabled',
                'outlined-light': 'hover:bg-light',
                'outlined-dark': 'hover:bg-dark',
                'outlined-none': '',
                'contained-primary': 'hover:bg-opacity-70',
                'contained-secondary': 'hover:bg-opacity-70',
                'contained-success': 'hover:bg-opacity-70',
                'contained-info': 'hover:bg-opacity-70',
                'contained-warning': 'hover:bg-opacity-70',
                'contained-danger': 'hover:bg-opacity-70',
                'contained-disabled': 'hover:bg-opacity-50',
                'contained-light': 'hover:bg-opacity-50',
                'contained-dark': 'hover:bg-opacity-70',
                'contained-none': '',
                'light-primary': 'hover:bg-primary hover:bg-opacity-20',
                'light-secondary': 'hover:bg-secondary hover:bg-opacity-5',
                'light-success': 'hover:bg-success hover:bg-opacity-5',
                'light-info': 'hover:bg-info hover:bg-opacity-5',
                'light-warning': 'hover:bg-warning hover:bg-opacity-5',
                'light-danger': 'hover:bg-danger hover:bg-opacity-5',
                'light-disabled': 'hover:bg-disabled hover:bg-opacity-5',
                'light-light': 'hover:bg-light hover:bg-opacity-5',
                'light-dark': 'hover:bg-dark hover:bg-opacity-5',
                'light-none': 'hover:bg-none hover:bg-opacity-5',
            },
        },
    },
    textSize: {
        default: 'base',
        styles: {
            xs: 'text-xs',
            sm: 'text-sm',
            md: 'text-base',
            base: 'text-base',
            lg: 'text-lg',
            xl: 'text-xl',
            '2xl': 'text-2xl',
            '3xl': 'text-3xl',
            '4xl': 'text-4xl',
            '5xl': 'text-5xl',
            '6xl': 'text-6xl',
            '7xl': 'text-7xl',
            '8xl': 'text-8xl',
            '9xl': 'text-9xl',
        },
    },
    boxTextSize: {
        default: 'base',
        styles: {
            common: {
                xs: 'text-xs',
                sm: 'text-sm',
                md: 'text-base',
                base: 'text-base',
                lg: 'text-lg',
                xl: 'text-xl',
                '2xl': 'text-2xl',
                '3xl': 'text-3xl',
                '4xl': 'text-4xl',
                '5xl': 'text-5xl',
                '6xl': 'text-6xl',
                '7xl': 'text-7xl',
                '8xl': 'text-8xl',
                '9xl': 'text-9xl',
            },
        },
    },
    textVariant: {
        default: 'body',
        styles: {
            xsmall: 'text-xxs font-small',
            small: 'text-xs font-small',
            body: 'text-base font-body',
            text: 'text-base font-body',
            section: 'text-base font-body font-medium tracking-wide leading-tight',
            cross: 'text-base line-through',
            description: 'text-sm font-description',
            overline: 'text-sm font-overline',
            subsection: 'text-sm font-subsection font-medium',
            subtitle: 'text-lg font-light font-subtitle',
            sl: 'text-2xl',
            title0: 'xl:text-9xl md:text-7xl sm:text-4xl font-bold font-title1',
            title1: 'text-6xl font-bold font-title1',
            title2: 'text-5xl font-bold font-title2',
            title3: 'text-4xl font-bold font-title3',
            title4: 'text-3xl font-bold font-title4',
            title5: 'text-2xl font-bold font-title5',
            title6: 'text-xl font-bold font-title6',
            title7: 'text-9xl sm:text-xxl font-bold font-title7 m-0 sm:-mt-12 sm:-mb-8',
            underline: 'text-base underline font-underline',
        },
    },
    textColor: {
        default: 'inherit',
        styles: {
            primary: 'text-primary',
            secondary: 'text-secondary',
            success: 'text-success',
            info: 'text-info',
            warning: 'text-warning',
            danger: 'text-danger',
            dark: 'text-dark',
            disabled: 'text-disabled',
            light: 'text-light',
            none: '',
            default: '',
            inherit: 'text-current',
        },
    },
    boxTextColor: {
        styles: {
            common: {
                'filled-primary': 'text-primary',
                'filled-secondary': 'text-secondary',
                'filled-success': 'text-success',
                'filled-info': 'text-info',
                'filled-warning': 'text-warning',
                'filled-danger': 'text-danger',
                'filled-disabled': 'text-disabled',
                'filled-light': 'text-light',
                'filled-dark': 'text-dark',
                'filled-none': 'text-dark',
                'outlined-primary': 'text-primary',
                'outlined-secondary': 'text-secondary',
                'outlined-success': 'text-success',
                'outlined-info': 'text-info',
                'outlined-warning': 'text-warning',
                'outlined-danger': 'text-danger',
                'outlined-disabled': 'text-disabled',
                'outlined-light': 'text-light',
                'outlined-dark': 'text-dark',
                'outlined-none': 'text-dark',
                'contained-primary': 'text-clear',
                'contained-secondary': 'text-clear',
                'contained-success': 'text-clear',
                'contained-info': 'text-clear',
                'contained-warning': 'text-clear',
                'contained-danger': 'text-clear',
                'contained-disabled': 'text-clear',
                'contained-light': 'text-clear',
                'contained-dark': 'text-clear',
                'contained-none': 'text-dark',
                'light-primary': 'text-dark',
                'light-secondary': 'text-dark',
                'light-success': 'text-dark',
                'light-info': 'text-dark',
                'light-warning': 'text-dark',
                'light-danger': 'text-dark',
                'light-disabled': 'text-dark',
                'light-light': 'text-dark',
                'light-dark': 'text-dark',
                'light-none': 'text-dark',
            },
            nonDisabled: {
                'filled-primary': '',
                'filled-secondary': '',
                'filled-success': '',
                'filled-info': '',
                'filled-warning': '',
                'filled-danger': '',
                'filled-disabled': '',
                'filled-light': '',
                'filled-dark': '',
                'filled-none': 'text-dark',
                'outlined-primary': '',
                'outlined-secondary': '',
                'outlined-success': '',
                'outlined-info': '',
                'outlined-warning': '',
                'outlined-danger': '',
                'outlined-disabled': '',
                'outlined-light': '',
                'outlined-dark': '',
                'outlined-none': 'text-dark',
                'contained-primary': '',
                'contained-secondary': '',
                'contained-success': '',
                'contained-info': '',
                'contained-warning': '',
                'contained-danger': '',
                'contained-disabled': '',
                'contained-light': '',
                'contained-dark': '',
                'contained-none': 'text-dark',
                'light-primary': '',
                'light-secondary': '',
                'light-success': '',
                'light-info': '',
                'light-warning': '',
                'light-danger': '',
                'light-disabled': '',
                'light-light': '',
                'light-dark': '',
                'light-none': 'text-dark',
            },
            hoverable: {
                'filled-primary': '',
                'filled-secondary': '',
                'filled-success': '',
                'filled-info': '',
                'filled-warning': '',
                'filled-danger': '',
                'filled-disabled': '',
                'filled-light': '',
                'filled-dark': '',
                'filled-none': '',
                'outlined-primary': 'hover:text-clear',
                'outlined-secondary': 'hover:text-clear',
                'outlined-success': 'hover:text-clear',
                'outlined-info': 'hover:text-clear',
                'outlined-warning': 'hover:text-clear',
                'outlined-danger': 'hover:text-clear',
                'outlined-disabled': 'hover:text-clear',
                'outlined-light': 'hover:text-clear',
                'outlined-dark': 'hover:text-clear',
                'outlined-none': '',
                'contained-primary': '',
                'contained-secondary': '',
                'contained-success': '',
                'contained-info': '',
                'contained-warning': '',
                'contained-danger': '',
                'contained-disabled': '',
                'contained-light': '',
                'contained-dark': '',
                'contained-none': '',
                'light-primary': 'hover:text-primary',
                'light-secondary': 'hover:text-secondary',
                'light-success': 'hover:text-success',
                'light-info': 'hover:text-info',
                'light-warning': 'hover:text-warning',
                'light-danger': 'hover:text-danger',
                'light-disabled': 'hover:text-disabled',
                'light-light': 'hover:text-light',
                'light-dark': 'hover:text-dark',
                'light-none': 'hover:text-none',
            },
        }
    },
    status: {
        default: 'unknown',
        styles: {
            online: 'bg-success',
            offline: 'bg-disabled',
            busy: 'bg-danger',
            unknown: 'bg-warning',
        },
    },
    spinner: {
        styles: {
            // xs
            'xs-dots': 'h-1 w-1 bg-danger rounded-full',
            'xs-dotted-circle': 'h-3 w-3 border-4 border-primary border-dotted',
            'xs-full-circle': 'h-3 w-3 border border-t-4 border-warning',
            'xs-half-circle': 'h-3 w-3 border-t-2 border-r-2 border-secondary',
            'xs-separate-circle': 'h-3 w-3 border-t-2 border-b-2 border-dark',
            'xs-squares': 'h-1 w-1 bg-info',
            'xs-circle': 'h-3 w-3 border-1 border-secondary x-border-l-transparent',
            // sm
            'sm-dots': 'h-2 w-2 bg-danger rounded-full',
            'sm-dotted-circle': 'h-4 w-4 border-4 border-primary border-dotted',
            'sm-full-circle': 'h-4 w-4 border border-t-4 border-warning',
            'sm-half-circle': 'h-4 w-4 border-t-2 border-r-2 border-secondary',
            'sm-separate-circle': 'h-4 w-4 border-t-2 border-b-2 border-dark',
            'sm-squares': 'h-2 w-1 bg-info',
            'sm-circle': 'h-4 w-4 border-2 border-secondary x-border-l-transparent',
            // md
            'md-dots': 'h-3 w-3 bg-danger rounded-full',
            'md-dotted-circle': 'h-6 w-6 border-4 border-primary border-dotted',
            'md-full-circle': 'h-6 w-6 border border-t-4 border-warning',
            'md-half-circle': 'h-6 w-6 border-t-2 border-r-2 border-secondary',
            'md-separate-circle': 'h-6 w-6 border-t-2 border-b-2 border-dark',
            'md-squares': 'h-3 w-1 bg-info',
            'md-circle': 'h-6 w-6 border-2 border-secondary x-border-l-transparent',
            // lg
            'lg-dots': 'h-4 w-4 bg-danger rounded-full',
            'lg-dotted-circle': 'h-10 w-10 border-4 border-primary border-dotted',
            'lg-full-circle': 'h-10 w-10 border border-t-4 border-warning',
            'lg-half-circle': 'h-10 w-10 border-t-2 border-r-2 border-secondary',
            'lg-separate-circle': 'h-10 w-10 border-t-2 border-b-2 border-dark',
            'lg-squares': 'h-4 w-1 bg-info',
            'lg-circle': 'h-10 w-10 border-3 border-secondary x-border-l-transparent',
            // xl
            'xl-dots': 'h-5 w-5 bg-danger rounded-full',
            'xl-dotted-circle': 'h-20 w-20 border-4 border-primary border-dotted',
            'xl-full-circle': 'h-20 w-20 border border-t-4 border-warning',
            'xl-half-circle': 'h-20 w-20 border-t-2 border-r-2 border-secondary',
            'xl-separate-circle': 'h-20 w-20 border-t-2 border-b-2 border-dark',
            'xl-squares': 'h-5 w-1 bg-info',
            'xl-circle': 'h-20 w-20 border-4 border-secondary x-border-l-transparent',
        },
    },
    colorSpinner: {
        styles: {
            // primary
            'primary-dots': 'bg-primary',
            'primary-dotted-circle': 'border-primary',
            'primary-full-circle': 'border-primary',
            'primary-half-circle': 'border-primary',
            'primary-separate-circle': 'border-primary',
            'primary-squares': 'bg-primary',
            'primary-circle': 'border-primary x-border-l-transparent',
            // secondary
            'secondary-dots': 'bg-secondary',
            'secondary-dotted-circle': 'border-secondary',
            'secondary-full-circle': 'border-secondary',
            'secondary-half-circle': 'border-secondary',
            'secondary-separate-circle': 'border-secondary',
            'secondary-squares': 'bg-secondary',
            'secondary-circle': 'border-secondary x-border-l-transparent',
            // success
            'success-dots': 'bg-success',
            'success-dotted-circle': 'border-success',
            'success-full-circle': 'border-success',
            'success-half-circle': 'border-success',
            'success-separate-circle': 'border-success',
            'success-squares': 'bg-success',
            'success-circle': 'border-success x-border-l-transparent',
            // info
            'info-dots': 'bg-info',
            'info-dotted-circle': 'border-info',
            'info-full-circle': 'border-info',
            'info-half-circle': 'border-info',
            'info-separate-circle': 'border-info',
            'info-squares': 'bg-info',
            'info-circle': 'border-info x-border-l-transparent',
            // warning
            'warning-dots': 'bg-warning',
            'warning-dotted-circle': 'border-warning',
            'warning-full-circle': 'border-warning',
            'warning-half-circle': 'border-warning',
            'warning-separate-circle': 'border-warning',
            'warning-squares': 'bg-warning',
            'warning-circle': 'border-warning x-border-l-transparent',
            // danger
            'danger-dots': 'bg-danger',
            'danger-dotted-circle': 'border-danger',
            'danger-full-circle': 'border-danger',
            'danger-half-circle': 'border-danger',
            'danger-separate-circle': 'border-danger',
            'danger-squares': 'bg-danger',
            'danger-circle': 'border-danger x-border-l-transparent',
            // light
            'light-dots': 'bg-light',
            'light-dotted-circle': 'border-light',
            'light-full-circle': 'border-light',
            'light-half-circle': 'border-light',
            'light-separate-circle': 'border-light',
            'light-squares': 'bg-light',
            'light-circle': 'border-light x-border-l-transparent',
            // dark
            'dark-dots': 'bg-dark',
            'dark-dotted-circle': 'border-dark',
            'dark-full-circle': 'border-dark',
            'dark-half-circle': 'border-dark',
            'dark-separate-circle': 'border-dark',
            'dark-squares': 'bg-dark',
            'dark-circle': 'border-dark x-border-l-transparent',
        },
    },
    spinnerVariant: {
        default: 'squares',
        styles: {
            dots: true,
            'dotted-circle': true,
            'full-circle': true,
            'half-circle': true,
            'separate-circle': true,
            squares: true,
            circle: true,
        },
    },
    spinnerColor: {
        styles: {
            primary: true,
            secondary: true,
            success: true,
            info: true,
            warning: true,
            danger: true,
            light: true,
            dark: true,
        },
    },
    spinnerSize: {
        default: 'md',
        styles: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true,
        },
    },
    space: {
        default: 'default',
        styles: {
            default: '',
            'responsive-col--4': '-space-y-4',
            'responsive-col-0': 'space-y-0',
            'responsive-col-1': 'space-y-1',
            'responsive-col-2': 'space-y-2',
            'responsive-col-3': 'space-y-3',
            'responsive-col-4': 'space-y-4',
            'responsive-col-6': 'space-y-6',
            'responsive-col-14': 'space-y-14',
            'responsive-col-30': 'space-y-0 sm:space-y-3',
            'responsive-col-420': 'space-y-0 sm:space-y-2 md:space-y-4',
            'responsive--4': '-space-y-4 sm:space-y-0 sm:-space-x-4',
            'responsive-0': 'space-y-0 sm:space-x-0',
            'responsive-1': 'space-y-1 sm:space-y-0 sm:space-x-1',
            'responsive-2': 'space-y-2 sm:space-y-0 sm:space-x-2',
            'responsive-3': 'space-y-3 sm:space-y-0 sm:space-x-3',
            'responsive-4': 'space-y-4 sm:space-y-0 sm:space-x-4',
            'responsive-6': 'space-y-6 sm:space-y-0 sm:space-x-6',
            'responsive-14': 'space-y-14 sm:space-y-0 sm:space-x-14',
            'responsive-30': 'space-y-0 sm:space-y-0 sm:space-x-3',
            'responsive-420': 'space-y-0 sm:space-y-0 sm:space-x-2 md:space-x-4',
            'non-responsive-col--4': '-space-y-4',
            'non-responsive-col-0': 'space-y-0',
            'non-responsive-col-1': 'space-y-1',
            'non-responsive-col-2': 'space-y-2',
            'non-responsive-col-3': 'space-y-3',
            'non-responsive-col-4': 'space-y-4',
            'non-responsive-col-6': 'space-y-6',
            'non-responsive-col-14': 'space-y-14',
            'non-responsive-col-30': 'space-y-0 sm:space-y-3',
            'non-responsive-col-420': 'space-y-0 sm:space-y-2 md:space-y-4',
            'non-responsive--4': '-space-x-4',
            'non-responsive-0': 'space-x-0',
            'non-responsive-1': 'space-x-1',
            'non-responsive-2': 'space-x-2',
            'non-responsive-3': 'space-x-3',
            'non-responsive-4': 'space-x-4',
            'non-responsive-6': 'space-x-6',
            'non-responsive-14': 'space-x-14',
            'non-responsive-30': 'space-x-3',
            'non-responsive-420': 'space-x-0 sm:space-x-2 md:space-x-4',
        },
    },
    shareIconType: {
        default: 'default',
        styles: {
            default: 'fa-far--moon',
            // free brands
            facebook: 'fa-fab--facebook-f',
            googleplus: 'fa-fab--google-plus-g',
            twitter: 'fa-fab--twitter',
            instagram: 'fa-fab--instagram',
            // solid svg
            rss: 'fa-fas--rss',
        },
    },
    shape: {
        default: 'square',
        styles: {
            circular: 'rounded-full',
            rounded: 'rounded-lg',
            square: '',
        },
    },
    ratingVariant: {
        default: 'squares',
        styles: {
            units: '',
            plates: '',
            'labelled-squares': '',
            pill: '',
            squares: '',
            'reverse-squares': '',
            'small-stars': '',
            stack: '',
            stars: '',
            basic: '',
            mood: '',
        },
    },
    pushImagePosition: {
        default: 'default',
        styles: {
            left: 'flex flex-row-reverse',
            right: 'flex flex-row',
            bottom: 'flex flex-col',
            top: 'flex flex-col-reverse',
        },
    },
    progressVariant: {
        default: 'default',
        styles: {
            circular: '',
            linear: '',
            default: '',
        },
    },
    position: {
        default: 'default',
        styles: {
            left: 'flex flex-row-reverse',
            right: 'flex flex-row',
            bottom: 'flex flex-col',
            top: 'flex flex-col-reverse',
        },
    },
    labelPlacement: {
        default: 'right',
        styles: {
            left: 'flex-row-reverse space-x-2',
            right: 'space-x-2',
            top: 'flex-col-reverse space-y-2',
            bottom: 'flex-col space-y-2',
            none: '',
        },
    },
    imageSize: {
        default: 'none',
        styles: {
            none: '',
            lg: 'w-24 h-24',
            'lg-w': 'w-24',
            'lg-h': 'h-24',
            xl: 'w-40 h-40',
            'xl-w': 'w-40',
            'xl-h': 'h-40',
        },
    },
    iconColor: {
        default: 'default',
        styles: {
            primary: 'primary',
            secondary: 'secondary',
            danger: 'error',
            default: undefined,
            inherit: 'inherit',
        },
    },
    hoverAnimation: {
        default: 'none',
        styles: {
            none: '',
            spin: 'hover:animate-spin',
            ping: 'hover:animate-ping',
            pulse: 'hover;animated hover:pulsing',
            bounce: 'hover:animate-bounce',
            flash: 'hover:animate-pulse',
            tada: 'animated tada',
            shake: 'animated shake',
            wobble: 'animated wobble',
            jackInTheBox: 'animated jackInTheBox',
            flipInX: 'animated flipInX',
            flipInY: 'animated flipInY',
        },
    },
    height: {
        default: 'default',
        styles: {
            '0': '',
            '1': 'h-1',
            '2': 'h-2',
            '3': 'h-3',
            xs: 'h-4',
            '4': 'h-4',
            '5': 'h-5',
            '6': 'h-6',
            '7': 'h-7',
            '8': 'h-8',
            '9': 'h-9',
            sm: 'h-10',
            md: 'h-16',
            lg: 'h-20',
            xl: 'h-24',
            '2xl': 'h-30',
            '3xl': 'h-40',
            default: 'h-16',
        },
    },
    headingTextVariant: {
        default: 'none',
        styles: {
            none: { title: 'title1', subtitle: 'subtitle' },
            section: { title: 'section', subtitle: 'subsection' },
            xsection: { title: 'section', subtitle: 'xsmall' },
            ctasection: { title: 'title3', subtitle: 'title3' },
            small: { title: 'title6', subtitle: 'body' },
            xxsmall: { title: 'title6', subtitle: 'overline' },
            xxsmall2: { title: 'title6', subtitle: 'title3' },
            xxsmall3: { title: 'title6', subtitle: 'subtitle' },
            xsmall: { title: 'subtitle', subtitle: 'xsmall' },
            smmd: { title: 'title5', subtitle: 'overline' },
            smmd2: { title: 'title5', subtitle: 'subtitle' },
            medium: { title: 'title3', subtitle: 'subtitle' },
            medium2: { title: 'title3', subtitle: 'subtitle', description: 'body' },
            medium3: { title: 'body', subtitle: 'title4' },
            large: { title: 'title2', subtitle: 'title6' },
            xlarge: { title: 'title1', subtitle: 'title5' },
            underlined: { title: 'underline', subtitle: 'small' },
        },
    },
    gridCol: {
        default: 'default',
        styles: {
            '0': 'grid-cols-none',
            '1': 'grid-cols-1',
            '2': 'grid-cols-1 sm:grid-cols-2',
            '3': 'grid-cols-1 sm:grid-cols-3',
            '4': 'grid-cols-1 sm:grid-cols-4',
            '5': 'grid-cols-1 sm:grid-cols-5',
            '6': 'grid-cols-1 sm:grid-cols-4 md:grid-cols-6',
            '7': 'grid-cols-1 sm:grid-cols-5 md:grid-cols-7',
            '8': 'grid-cols-1 sm:grid-cols-4 md:grid-cols-8',
            '9': 'grid-cols-1 sm:grid-cols-5 md:grid-cols-9',
            '10': 'grid-cols-1 sm:grid-cols-4 md:grid-cols-10',
            '11': 'grid-cols-1 sm:grid-cols-5 md:grid-cols-11',
            '12': 'grid-cols-1 sm:grid-cols-4 md:grid-cols-12',
            '64': 'grid-cols-4 sm:grid-cols-6',
            default: 'grid-cols-none',
        },
    },
    gap: {
        default: 'default',
        styles: {
            '0': '',
            '1': 'gap-1',
            '2': 'gap-1 sm:gap-2',
            '3': 'gap-1 sm:gap-3',
            '4': 'gap-2 sm:gap-4',
            '5': 'gap-2 sm:gap-5',
            '6': 'gap-2 sm:gap-6',
            '7': 'gap-3 sm:gap-7',
            '8': 'gap-4 sm:gap-8',
            '9': 'gap-5 sm:gap-9',
            '10': 'gap-6 sm:gap-10',
            '11': 'gap-7 sm:gap-11',
            '12': 'gap-8 sm:gap-12',
            '64': 'gap-32 sm:gap-64',
            default: 'gap-1 sm:gap-2',
        },
    },
    elevation: {
        default: 1,
        styles: {
            '0': '',
            '1': 'shadow-sm',
            '2': 'shadow',
            '3': 'shadow-md',
            '4': 'shadow-lg',
            '5': 'shadow-xl',
            '6': 'shadow-2xl',
            '7': 'shadow-block',
            '8': 'shadow-upper-block',
        },
    },
    dividerSize: {
        default: 'default',
        styles: {
            xs: 'h-1',
            sm: 'h-2',
            md: 'h-3',
            lg: 'h-4',
            xl: 'h-6',
            default: 'h-1',
        },
    },
    corner: {
        default: 'square',
        styles: {
            none: '',
            'r-sm': 'rounded-sm',
            rounded: 'rounded-2xl',
            'top-rounded': 'rounded-t-2xl',
            'left-rounded': 'rounded-l-2xl',
            'rounded-small': 'rounded-xl',
            'top-rounded-small': 'rounded-t-xl',
            'left-rounded-small': 'rounded-l-xl',
            'rounded-xsmall': 'rounded-lg',
            'top-rounded-xsmall': 'rounded-t-lg',
            'left-rounded-xsmall': 'rounded-l-lg',
            circle: 'rounded-full',
            'rounded-xxsmall': 'rounded',
            square: '',
        },
    },
    colSpan: {
        default: '',
        styles: {
            '0': 'col-span-full',
            '1': 'col-span-1',
            '2': 'col-span-1 sm:col-span-2',
            '3': 'col-span-1 sm:col-span-3',
            '4': 'col-span-1 sm:col-span-4',
            '5': 'col-span-1 sm:col-span-5',
            '6': 'col-span-1 sm:col-span-4 md:col-span-6',
            '7': 'col-span-1 sm:col-span-5 md:col-span-7',
            '8': 'col-span-1 sm:col-span-4 md:col-span-8',
            '9': 'col-span-1 sm:col-span-5 md:col-span-9',
            '10': 'col-span-1 sm:col-span-4 md:col-span-10',
            '11': 'col-span-1 sm:col-span-5 md:col-span-11',
            '12': 'col-span-1 sm:col-span-4 md:col-span-12',
            '64': 'col-span-4 sm:col-span-6',
            default: 'col-auto',
        },
    },
    width: {
        default: 'default',
        styles: {
            '0': '',
            '1': 'w-1',
            '2': 'w-2',
            '3': 'w-3',
            xs: 'w-4',
            '4': 'w-4',
            '5': 'w-5',
            '6': 'w-6',
            '7': 'w-7',
            '8': 'w-8',
            '9': 'w-9',
            sm: 'w-10',
            md: 'w-16',
            lg: 'w-20',
            xl: 'w-24',
            '2xl': 'w-30',
            '3xl': 'w-40',
            default: 'w-16',
        },
    },
    thumbnailSize: {
        default: 'xl',
        styles: {
            xl: 'w-24 h-30 text-5xl',
        },
    },
    boxVariant: {
        default: 'filled',
        styles: {
            filled: '',
            outlined: '',
            contained: '',
            none: '',
        },
    },
    boxSize: {
        default: 'none',
        styles: {
            none: '',
            xs: 'w-6 h-6',
            'xs-w': 'w-6',
            'xs-h': 'h-6',
            _xs: 'max-w-6 max-h-6',
            '_xs-w': 'max-w-6',
            '_xs-h': 'max-h-6',
            sm: 'w-14 h-14',
            'sm-w': 'w-14',
            'sm-h': 'h-14',
            _sm: 'max-w-14 max-h-14',
            '_sm-w': 'max-w-14',
            '_sm-h': 'max-h-14',
            md: 'w-32 h-32',
            'md-w': 'w-32',
            'md-h': 'h-32',
            _md: 'max-w-32 max-h-32',
            '_md-w': 'max-w-32',
            '_md-h': 'max-h-32',
            lg: 'w-44 h-44',
            'lg-w': 'w-44',
            'lg-h': 'h-44',
            _lg: 'max-w-44 max-h-44',
            '_lg-w': 'max-w-44',
            '_lg-h': 'max-h-44',
            xl: 'w-80 h-80',
            'xl-w': 'w-80',
            'xl-h': 'h-80',
            _xl: 'max-w-80 max-h-80',
            '_xl-w': 'max-w-80',
            '_xl-h': 'max-h-80',
        },
    },
    boxColor: {
        default: 'default',
        styles: {
            primary: 'bg-primary',
            secondary: 'bg-secondary',
            success: 'bg-success',
            info: 'bg-info',
            warning: 'bg-warning',
            danger: 'bg-danger',
            disabled: 'bg-disabled',
            light: 'bg-light',
            dark: 'bg-dark',
            none: 'bg-clear',
            clear: 'bg-clear',
            default: '',
        },
    },
    blockVariant: {
        default: 'filled',
        styles: {
            filled: '',
            outlined: '',
            contained: '',
            none: '',
            'header-contained': '',
        },
    },
    avatarSize: {
        default: 'md',
        styles: {
            xs: 'w-8 h-8 text-xs',
            xsm: 'w-10 h-10 text-base',
            sm: 'w-12 h-12 text-base',
            md: 'w-14 h-14 text-3xl',
            lg: 'w-20 h-20 text-4xl',
            xl: 'w-24 h-24 text-5xl',
        },
    },
    alignment: {
        default: 'none',
        styles: {
            left: 'text-left',
            center: 'text-center',
            right: 'text-right',
            none: '',
        },
    },
    paddingX: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'px-auto',
            xs: 'px-1',
            _xs: 'px-0.5 sm:px-1',
            sm: 'px-2',
            _sm: 'px-1 sm:px-2',
            xmd: 'px-3',
            _xmd: 'px-1.5 sm:px-3',
            md: 'px-4',
            _md: 'px-2 sm:px-4',
            xlg: 'px-5',
            _xlg: 'px-2.5 sm:px-5',
            lg: 'px-6',
            _lg: 'px-3 sm:px-6',
            xexl: 'px-7',
            _xexl: 'px-3.5 sm:px-7',
            xl: 'px-8',
            _xl: 'px-4 sm:px-8',
            xl_: 'px-8 sm:px-0',
            sl: 'px-10',
            _sl: 'px-5 sm:px-10',
            hl: 'px-16',
            _hl: 'px-8 sm:px-16',
            ul: 'px-32',
            _ul: 'px-16 sm:px-32',
        },
    },
    paddingY: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'py-auto',
            xs: 'py-1',
            _xs: 'py-0.5 sm:py-1',
            sm: 'py-2',
            _sm: 'py-1 sm:py-2',
            xmd: 'py-3',
            _xmd: 'py-1.5 sm:py-3',
            md: 'py-4',
            _md: 'py-2 sm:py-4',
            xlg: 'py-5',
            _xlg: 'py-2.5 sm:py-5',
            lg: 'py-6',
            _lg: 'py-3 sm:py-6',
            xexl: 'py-7',
            _xexl: 'py-3.5 sm:py-7',
            xl: 'py-8',
            _xl: 'py-4 sm:py-8',
            xl_: 'py-8 sm:py-0',
            sl: 'py-10',
            _sl: 'py-5 sm:py-10',
            hl: 'py-16',
            _hl: 'py-8 sm:py-16',
            ul: 'py-32',
            _ul: 'py-16 sm:py-32',
        },
    },
    paddingTop: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'pt-auto',
            xs: 'pt-1',
            _xs: 'pt-0.5 sm:pt-1',
            sm: 'pt-2',
            _sm: 'pt-1 sm:pt-2',
            xmd: 'pt-3',
            _xmd: 'pt-1.5 sm:pt-3',
            md: 'pt-4',
            _md: 'pt-2 sm:pt-4',
            xlg: 'pt-5',
            _xlg: 'pt-2.5 sm:pt-5',
            lg: 'pt-6',
            _lg: 'pt-3 sm:pt-6',
            xexl: 'pt-7',
            _xexl: 'pt-3.5 sm:pt-7',
            xl: 'pt-8',
            _xl: 'pt-4 sm:pt-8',
            xl_: 'pt-8 sm:pt-0',
            sl: 'pt-10',
            _sl: 'pt-5 sm:pt-10',
            hl: 'pt-16',
            _hl: 'pt-8 sm:pt-16',
            ul: 'pt-32',
            _ul: 'pt-16 sm:pt-32',
        },
    },
    paddingBottom: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'pb-auto',
            xs: 'pb-1',
            _xs: 'pb-0.5 sm:pb-1',
            sm: 'pb-2',
            _sm: 'pb-1 sm:pb-2',
            xmd: 'pb-3',
            _xmd: 'pb-1.5 sm:pb-3',
            md: 'pb-4',
            _md: 'pb-2 sm:pb-4',
            xlg: 'pb-5',
            _xlg: 'pb-2.5 sm:pb-5',
            lg: 'pb-6',
            _lg: 'pb-3 sm:pb-6',
            xexl: 'pb-7',
            _xexl: 'pb-3.5 sm:pb-7',
            xl: 'pb-8',
            _xl: 'pb-4 sm:pb-8',
            xl_: 'pb-8 sm:pb-0',
            sl: 'pb-10',
            _sl: 'pb-5 sm:pb-10',
            hl: 'pb-16',
            _hl: 'pb-8 sm:pb-16',
            ul: 'pb-32',
            _ul: 'pb-16 sm:pb-32',
        },
    },
    paddingLeft: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'pl-auto',
            xs: 'pl-1',
            _xs: 'pl-0.5 sm:pl-1',
            sm: 'pl-2',
            _sm: 'pl-1 sm:pl-2',
            xmd: 'pl-3',
            _xmd: 'pl-1.5 sm:pl-3',
            md: 'pl-4',
            _md: 'pl-2 sm:pl-4',
            xlg: 'pl-5',
            _xlg: 'pl-2.5 sm:pl-5',
            lg: 'pl-6',
            _lg: 'pl-3 sm:pl-6',
            xexl: 'pl-7',
            _xexl: 'pl-3.5 sm:pl-7',
            xl: 'pl-8',
            _xl: 'pl-4 sm:pl-8',
            xl_: 'pl-8 sm:pl-0',
            sl: 'pl-10',
            _sl: 'pl-5 sm:pl-10',
            hl: 'pl-16',
            _hl: 'pl-8 sm:pl-16',
            ul: 'pl-32',
            _ul: 'pl-16 sm:pl-32',
        },
    },
    paddingRight: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'pr-auto',
            xs: 'pr-1',
            _xs: 'pr-0.5 sm:pr-1',
            sm: 'pr-2',
            _sm: 'pr-1 sm:pr-2',
            xmd: 'pr-3',
            _xmd: 'pr-1.5 sm:pr-3',
            md: 'pr-4',
            _md: 'pr-2 sm:pr-4',
            xlg: 'pr-5',
            _xlg: 'pr-2.5 sm:pr-5',
            lg: 'pr-6',
            _lg: 'pr-3 sm:pr-6',
            xexl: 'pr-7',
            _xexl: 'pr-3.5 sm:pr-7',
            xl: 'pr-8',
            _xl: 'pr-4 sm:pr-8',
            xl_: 'pr-8 sm:pr-0',
            sl: 'pr-10',
            _sl: 'pr-5 sm:pr-10',
            hl: 'pr-16',
            _hl: 'pr-8 sm:pr-16',
            ul: 'pr-32',
            _ul: 'pr-16 sm:pr-32',
        },
    },
    marginX: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'mx-auto',
            xs: 'mx-1',
            _xs: 'mx-0 sm:mx-1',
            sm: 'mx-2',
            _sm: 'mx-0 sm:mx-2',
            xmd: 'mx-3',
            _xmd: 'mx-0 sm:mx-3',
            md: 'mx-4',
            _md: 'mx-0 sm:mx-4',
            xlg: 'mx-5',
            _xlg: 'mx-0 sm:mx-5',
            lg: 'mx-6',
            _lg: 'mx-2 sm:mx-4 md:mx-6',
            xl: 'mx-8',
            _xl: 'mx-2 sm:mx-4 md:mx-8',
            sl: 'mx-10',
            _sl: 'mx-4 sm:mx-6 md:mx-10',
        },
    },
    marginY: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'my-auto',
            xs: 'my-1',
            _xs: 'my-0 sm:my-1',
            sm: 'my-2',
            _sm: 'my-0 sm:my-2',
            xmd: 'my-3',
            _xmd: 'my-0 sm:my-3',
            md: 'my-4',
            _md: 'my-0 sm:my-4',
            xlg: 'my-5',
            _xlg: 'my-0 sm:my-5',
            lg: 'my-6',
            _lg: 'my-2 sm:my-4 md:my-6',
            xl: 'my-8',
            _xl: 'my-2 sm:my-4 md:my-8',
            sl: 'my-10',
            _sl: 'my-4 sm:my-6 md:my-10',
        },
    },
    marginTop: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'mt-auto',
            xs: 'mt-1',
            _xs: 'mt-0 sm:mt-1',
            sm: 'mt-2',
            _sm: 'mt-0 sm:mt-2',
            xmd: 'mt-3',
            _xmd: 'mt-0 sm:mt-3',
            md: 'mt-4',
            _md: 'mt-0 sm:mt-4',
            xlg: 'mt-5',
            _xlg: 'mt-0 sm:mt-5',
            lg: 'mt-6',
            _lg: 'mt-2 sm:mt-4 md:mt-6',
            xl: 'mt-8',
            _xl: 'mt-2 sm:mt-4 md:mt-8',
            sl: 'mt-10',
            _sl: 'mt-4 sm:mt-6 md:mt-10',
        },
    },
    marginBottom: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'mb-auto',
            xs: 'mb-1',
            _xs: 'mb-0 sm:mb-1',
            sm: 'mb-2',
            _sm: 'mb-0 sm:mb-2',
            xmd: 'mb-3',
            _xmd: 'mb-0 sm:mb-3',
            md: 'mb-4',
            _md: 'mb-0 sm:mb-4',
            xlg: 'mb-5',
            _xlg: 'mb-0 sm:mb-5',
            lg: 'mb-6',
            _lg: 'mb-2 sm:mb-4 md:mb-6',
            xl: 'mb-8',
            _xl: 'mb-2 sm:mb-4 md:mb-8',
            sl: 'mb-10',
            _sl: 'mb-4 sm:mb-6 md:mb-10',
        },
    },
    marginLeft: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'ml-auto',
            xs: 'ml-1',
            _xs: 'ml-0 sm:ml-1',
            sm: 'ml-2',
            _sm: 'ml-0 sm:ml-2',
            xmd: 'ml-3',
            _xmd: 'ml-0 sm:ml-3',
            md: 'ml-4',
            _md: 'ml-0 sm:ml-4',
            xlg: 'ml-5',
            _xlg: 'ml-0 sm:ml-5',
            lg: 'ml-6',
            _lg: 'ml-2 sm:ml-4 md:ml-6',
            xl: 'ml-8',
            _xl: 'ml-2 sm:ml-4 md:ml-8',
            sl: 'ml-10',
            _sl: 'ml-4 sm:ml-6 md:ml-10',
            _3xl: 'ml-0 sm:ml-32',
        },
    },
    marginRight: {
        default: 'none',
        styles: {
            none: '',
            default: '',
            auto: 'mr-auto',
            xs: 'mr-1',
            _xs: 'mr-0 sm:mr-1',
            sm: 'mr-2',
            _sm: 'mr-0 sm:mr-2',
            xmd: 'mr-3',
            _xmd: 'mr-0 sm:mr-3',
            md: 'mr-4',
            _md: 'mr-0 sm:mr-4',
            xlg: 'mr-5',
            _xlg: 'mr-0 sm:mr-5',
            lg: 'mr-6',
            _lg: 'mr-2 sm:mr-4 md:mr-6',
            xl: 'mr-8',
            _xl: 'mr-2 sm:mr-4 md:mr-8',
            sl: 'mr-10',
            _sl: 'mr-4 sm:mr-6 md:mr-10',
        },
    },
    borderX: {
        default: 'none',
        styles: {
            default: 'border-l border-r',
            none: '',
            xs: 'border-l border-r',
            sm: 'border-l-2 border-r-2',
            md: 'border-l-3 border-r-3',
            lg: 'border-l-4 border-r-4',
            xl: 'border-l-5 border-r-5',
            sl: 'border-l-6 border-r-6',
        },
    },
    borderY: {
        default: 'none',
        styles: {
            default: 'border-t border-b',
            none: '',
            xs: 'border-t border-b',
            sm: 'border-t-2 border-b-2',
            md: 'border-t-3 border-b-3',
            lg: 'border-t-4 border-b-4',
            xl: 'border-t-5 border-b-5',
            sl: 'border-t-6 border-b-6',
        },
    },
    borderTop: {
        default: 'none',
        styles: {
            default: 'border-t',
            none: '',
            xs: 'border-t',
            sm: 'border-t-2',
            md: 'border-t-3',
            lg: 'border-t-4',
            xl: 'border-t-5',
            sl: 'border-t-6',
        },
    },
    borderBottom: {
        default: 'none',
        styles: {
            default: 'border-b',
            none: '',
            xs: 'border-b',
            sm: 'border-b-2',
            md: 'border-b-3',
            lg: 'border-b-4',
            xl: 'border-b-5',
            sl: 'border-b-6',
        },
    },
    borderLeft: {
        default: 'none',
        styles: {
            default: 'border-l',
            none: '',
            xs: 'border-l',
            sm: 'border-l-2',
            md: 'border-l-3',
            lg: 'border-l-4',
            xl: 'border-l-5',
            sl: 'border-l-6',
        },
    },
    borderRight: {
        default: 'none',
        styles: {
            default: 'border-r',
            none: '',
            xs: 'border-r',
            sm: 'border-r-2',
            md: 'border-r-3',
            lg: 'border-r-4',
            xl: 'border-r-5',
            sl: 'border-r-6',
        },
    },
    fieldVariant: {
        default: 'flat',
        styles: {
            flat: '',
            edge: 'rounded-3xl',
            raise: 'rounded shadow-field',
        },
    },
    fieldPrependedVariant: {
        default: 'flat',
        styles: {
            flat: '',
            edge: 'rounded-l-3xl',
            raise: '',
        },
    },
    fieldAppendedVariant: {
        default: 'flat',
        styles: {
            flat: '',
            edge: 'rounded-r-3xl',
            raise: '',
        },
    },
    fieldInputVariant: {
        default: 'flat_pa',
        styles: {
            edge_: 'rounded-3xl',
            edge_p: 'rounded-r-3xl',
            edge_a: 'rounded-l-3xl',
            raise_: 'rounded',
            raise_p: 'rounded-r',
            raise_a: 'rounded-l',
        },
    },
    faSize: {
        default: 'base',
        styles: {
            xs: 'xs',
            sm: 'sm',
            md: 'lg',
            base: 'lg',
            lg: 'lg',
            xl: '2x',
            '2xl': '2x',
            '3xl': '3x',
            '4xl': '3x',
            '5xl': '5x',
            '6xl': '5x',
            '7xl': '7x',
            '8xl': '7x',
            '9xl': '10x',
        },
    },
    accordionCorner: {
        default: 'default',
        styles: {
            default: {
                none: '',
                'r-sm': 'rounded-sm',
                rounded: 'rounded-2xl',
                'top-rounded': 'rounded-t-2xl',
                'left-rounded': 'rounded-l-2xl',
                'rounded-small': 'rounded-xl',
                'top-rounded-small': 'rounded-t-xl',
                'left-rounded-small': 'rounded-l-xl',
                'rounded-xsmall': 'rounded-lg',
                'top-rounded-xsmall': 'rounded-t-lg',
                'left-rounded-xsmall': 'rounded-l-lg',
                circle: 'rounded-full',
                'rounded-xxsmall': 'rounded',
                square: '',
                default: 'rounded-md',
            },
            active: {
                none: '',
                'r-sm': 'rounded-t-sm rounded-b-sm',
                rounded: 'rounded-t-2xl rounded-b-2xl',
                'top-rounded': 'rounded-t-2xl',
                'left-rounded': 'rounded-l-2xl',
                'rounded-small': 'rounded-t-xl rounded-b-xl',
                'top-rounded-small': 'rounded-t-xl',
                'left-rounded-small': 'rounded-l-xl',
                'rounded-xsmall': 'rounded-t-lg rounded-b-lg',
                'top-rounded-xsmall': 'rounded-t-lg',
                'left-rounded-xsmall': 'rounded-l-lg',
                circle: 'rounded-t-full rounded-b-full',
                'rounded-xxsmall': 'rounded-t rounded-b',
                square: '',
                default: 'rounded-t-md rounded-b-md',
            },
        },
    },
    accordionSummaryCorner: {
        default: 'default',
        styles: {
            default: {
                none: '',
                'r-sm': 'rounded-sm',
                rounded: 'rounded-2xl',
                'top-rounded': 'rounded-t-2xl',
                'left-rounded': 'rounded-l-2xl',
                'rounded-small': 'rounded-xl',
                'top-rounded-small': 'rounded-t-xl',
                'left-rounded-small': 'rounded-l-xl',
                'rounded-xsmall': 'rounded-lg',
                'top-rounded-xsmall': 'rounded-t-lg',
                'left-rounded-xsmall': 'rounded-l-lg',
                circle: 'rounded-full',
                'rounded-xxsmall': 'rounded',
                square: '',
                default: 'rounded-md',
            },
            active: {
                none: '',
                'r-sm': 'rounded-t-sm',
                rounded: 'rounded-t-2xl',
                'top-rounded': 'rounded-t-2xl',
                'left-rounded': 'rounded-l-2xl',
                'rounded-small': 'rounded-t-xl',
                'top-rounded-small': 'rounded-t-xl',
                'left-rounded-small': 'rounded-l-xl',
                'rounded-xsmall': 'rounded-t-lg',
                'top-rounded-xsmall': 'rounded-t-lg',
                'left-rounded-xsmall': 'rounded-l-lg',
                circle: 'rounded-t-full',
                'rounded-xxsmall': 'rounded-t',
                square: '',
                default: 'rounded-t-md',
            },
        },
    },
    accordionDetailCorner: {
        default: 'default',
        styles: {
            default: {
                none: '',
                'r-sm': 'rounded-b-sm',
                rounded: 'rounded-b-2xl',
                'top-rounded': 'rounded-b-2xl',
                'left-rounded': 'rounded-l-2xl',
                'rounded-small': 'rounded-b-xl',
                'top-rounded-small': 'rounded-b-xl',
                'left-rounded-small': 'rounded-l-xl',
                'rounded-xsmall': 'rounded-b-lg',
                'top-rounded-xsmall': 'rounded-b-lg',
                'left-rounded-xsmall': 'rounded-l-lg',
                circle: 'rounded-b-full',
                'rounded-xxsmall': 'rounded-b',
                square: '',
                default: 'rounded-b-md',
            },
            active: {
                none: '',
                'r-sm': 'rounded-b-sm',
                rounded: 'rounded-b-2xl',
                'top-rounded': 'rounded-b-2xl',
                'left-rounded': 'rounded-l-2xl',
                'rounded-small': 'rounded-b-xl',
                'top-rounded-small': 'rounded-b-xl',
                'left-rounded-small': 'rounded-l-xl',
                'rounded-xsmall': 'rounded-b-lg',
                'top-rounded-xsmall': 'rounded-b-lg',
                'left-rounded-xsmall': 'rounded-l-lg',
                circle: 'rounded-b-full',
                'rounded-xxsmall': 'rounded-b',
                square: '',
                default: 'rounded-b-md',
            },
        },
    },
    boxBorder: {
        default: '',
        styles: {
            common: {
                'filled-primary': '',
                'filled-secondary': '',
                'filled-success': '',
                'filled-info': '',
                'filled-warning': '',
                'filled-danger': '',
                'filled-disabled': '',
                'filled-light': '',
                'filled-dark': '',
                'filled-none': '',
                'outlined-primary': 'border border-primary',
                'outlined-secondary': 'border border-secondary',
                'outlined-success': 'border border-success',
                'outlined-info': 'border border-info',
                'outlined-warning': 'border border-warning',
                'outlined-danger': 'border border-danger',
                'outlined-disabled': 'border border-disabled',
                'outlined-light': 'border border-light',
                'outlined-dark': 'border border-dark',
                'outlined-none': '',
                'contained-primary': '',
                'contained-secondary': '',
                'contained-success': '',
                'contained-info': '',
                'contained-warning': '',
                'contained-danger': '',
                'contained-disabled': '',
                'contained-light': '',
                'contained-dark': '',
                'contained-none': '',
            },
            nonDisabled: {
                'filled-primary': '',
                'filled-secondary': '',
                'filled-success': '',
                'filled-info': '',
                'filled-warning': '',
                'filled-danger': '',
                'filled-disabled': '',
                'filled-light': '',
                'filled-dark': '',
                'filled-none': '',
                'outlined-primary': '',
                'outlined-secondary': '',
                'outlined-success': '',
                'outlined-info': '',
                'outlined-warning': '',
                'outlined-danger': '',
                'outlined-disabled': '',
                'outlined-light': '',
                'outlined-dark': '',
                'outlined-none': '',
                'contained-primary': '',
                'contained-secondary': '',
                'contained-success': '',
                'contained-info': '',
                'contained-warning': '',
                'contained-danger': '',
                'contained-disabled': '',
                'contained-light': '',
                'contained-dark': '',
                'contained-none': '',
            },
            hoverable: {
                'filled-primary': '',
                'filled-secondary': '',
                'filled-success': '',
                'filled-info': '',
                'filled-warning': '',
                'filled-danger': '',
                'filled-disabled': '',
                'filled-light': '',
                'filled-dark': '',
                'filled-none': '',
                'outlined-primary': 'hover:border-transparent',
                'outlined-secondary': 'hover:border-transparent',
                'outlined-success': 'hover:border-transparent',
                'outlined-info': 'hover:border-transparent',
                'outlined-warning': 'hover:border-transparent',
                'outlined-danger': 'hover:border-transparent',
                'outlined-disabled': 'hover:border-transparent',
                'outlined-light': 'hover:border-transparent',
                'outlined-dark': 'hover:border-transparent',
                'outlined-none': '',
                'contained-primary': '',
                'contained-secondary': '',
                'contained-success': '',
                'contained-info': '',
                'contained-warning': '',
                'contained-danger': '',
                'contained-disabled': '',
                'contained-light': '',
                'contained-dark': '',
                'contained-none': '',
            },
        },
    },
}

preset['padding'] = {
    default: 'none',
    styles: {
        none: '',
        default: '',
        xs: 'p-1',
        _xs: 'p-0.5 sm:p-1',
        sm: 'p-2',
        _sm: 'p-1 sm:p-2',
        xmd: 'p-3',
        _xmd: 'p-1.5 sm:p-3',
        md: 'p-4',
        _md: 'p-2 sm:p-4',
        xlg: 'p-5',
        _xlg: 'p-2.5 sm:p-5',
        lg: 'p-6',
        _lg: 'p-3 sm:p-6',
        xexl: 'p-7',
        _xexl: 'p-3.5 sm:p-7',
        xl: 'p-8',
        _xl: 'p-4 sm:p-8',
        xl_: 'p-8 sm:p-0',
        sl: 'p-10',
        _sl: 'p-5 sm:p-10',
        hl: 'p-16',
        _hl: 'p-8 sm:p-16',
        ul: 'p-32',
        _ul: 'p-16 sm:p-32',
        ...Object.entries(preset.paddingX.styles).reduce((acc, [k, v]) => ({...acc, [`${k}-x`]: v}), {} as any),
        ...Object.entries(preset.paddingY.styles).reduce((acc, [k, v]) => ({...acc, [`${k}-y`]: v}), {} as any),
        ...Object.entries(preset.paddingTop.styles).reduce((acc, [k, v]) => ({...acc, [`${k}-t`]: v}), {} as any),
        ...Object.entries(preset.paddingBottom.styles).reduce((acc, [k, v]) => ({...acc, [`${k}-b`]: v}), {} as any),
        ...Object.entries(preset.paddingLeft.styles).reduce((acc, [k, v]) => ({...acc, [`${k}-l`]: v}), {} as any),
        ...Object.entries(preset.paddingRight.styles).reduce((acc, [k, v]) => ({...acc, [`${k}-r`]: v}), {} as any),
    },
};
preset['margin'] = {
    default: 'none',
    styles: {
        none: '',
        default: '',
        auto: 'm-auto',
        xs: 'm-1',
        _xs: 'm-0 sm:m-1',
        sm: 'm-2',
        _sm: 'm-0 sm:m-2',
        xmd: 'm-3',
        _xmd: 'm-0 sm:m-3',
        md: 'm-4',
        _md: 'm-0 sm:m-4',
        xlg: 'm-5',
        _xlg: 'm-0 sm:m-5',
        lg: 'm-6',
        _lg: 'm-2 sm:m-4 md:m-6',
        xl: 'm-8',
        _xl: 'm-2 sm:m-4 md:m-8',
        sl: 'm-10',
        _sl: 'm-4 sm:m-6 md:m-10',
        ...Object.entries(preset.marginX.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-x`]: v }), {} as any),
        ...Object.entries(preset.marginY.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-y`]: v }), {} as any),
        ...Object.entries(preset.marginTop.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-t`]: v }), {} as any),
        ...Object.entries(preset.marginBottom.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-b`]: v }), {} as any),
        ...Object.entries(preset.marginLeft.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-l`]: v }), {} as any),
        ...Object.entries(preset.marginRight.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-r`]: v }), {} as any),
    },
};
preset['border'] = {
    default: 'none',
    styles: {
        default: 'border',
        none: '',
        xs: 'border',
        sm: 'border-2',
        md: 'border-3',
        lg: 'border-4',
        xl: 'border-5',
        sl: 'border-6',
        ...Object.entries(preset.borderX.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-x`]: v }), {} as any),
        ...Object.entries(preset.borderY.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-y`]: v }), {} as any),
        ...Object.entries(preset.borderTop.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-t`]: v }), {} as any),
        ...Object.entries(preset.borderBottom.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-b`]: v }), {} as any),
        ...Object.entries(preset.borderLeft.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-l`]: v }), {} as any),
        ...Object.entries(preset.borderRight.styles).reduce((acc, [k, v]) => ({ ...acc, [`${k}-r`]: v }), {} as any),
    },
};

export default preset;
